<template>
  <div>
    <ejs-dialog
      ref="cartSearchPopup"
      header="카트 검색"
      :animationSettings="animationSettings"
      :allowDragging="true"
      :showCloseIcon="true"
      width="817"
      height="600"
      :close="dialogClose"
      v-on:mousedown.native="onDialogClicked"
      :position="popupPosition"
      :zIndex="currentzIndex"
      :isModal="$props.isModal"
      :visible="isVisible"
    >
      <div class="window cartSearch">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field cartKind">
                    <div class="title">카트종류</div>
                    <ul class="content">
                      <li class="item select">
                        <ejs-dropdownlist
                          id="cartKind"
                          ref="cartKindDropdown"
                          v-model="cartInfoOption.cartKind"
                          :dataSource="cartInfoOption.cartKindCodesOptions"
                          :allowFiltering="false"
                          :fields="{ text: 'comName', value: 'comCode' }"
                          :tabindex="-1"
                          cssClass="lookup-condition-dropdown"
                        />
                      </li>
                      <li class="item input">
                        <input-text
                          id="cartNoTextBox"
                          v-model="cartInfoOption.cartNo"
                          @keydown.enter="onCartNoTextBoxKeyDownEnter"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                v-model="cartInfoOption.dsuseFlag"
                                :tabindex="-1"
                              />
                              <i></i>
                              <div class="label">폐기제외</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                    button-div="GET"
                    :is-shortcut-button="true"
                    :tabindex="-1"
                    @click.native="getCartInfoList"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="cartInfoListGrid"
                        :allowResizing="true"
                        :dataSource="cartInfoList"
                        :selectionSettings="selectionSettings"
                        :columns="cartGridColumns"
                        :provides="grid"
                        :isInPopup="true"
                        @onGridDialogDoubleClickedOrEnterKeyed="
                          onCartInfoListGridDoubleClicked
                        "
                        @dataBound="onCartInfoListGridDataBound"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button button-div="SAVE" @click.native="onCartConfirmButton">
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="cartSearchPopupClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import { Resize, ForeignKey, Filter } from "@syncfusion/ej2-vue-grids";

import commonMixin from "@/views/layout/mixin/commonMixin";

import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";

import { getCartInfoList } from "@/api/cartInfo";

import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "cartSearchPopup",
  props: ["popupKey", "value", "position", "zIndex", "isModal", "isVisible"],
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputText,
    ejsGridWrapper,
    ErpButton,
  },
  data: function () {
    return {
      grid: [Filter, Resize, ForeignKey],
      popupPosition: { X: this.$props.position.x, Y: this.$props.position.y },
      currentzIndex: this.$props.zIndex,
      animationSettings: { effect: "None" },
      cartInfo: {
        cartId: null,
        cartNo: null,
      },
      cartInfoOption: {
        cartKind: null,
        cartKindCodesOptions: null,
        cartNo: null,
      },
      cartInfoList: [],
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      cartGridColumns: [
        {
          field: "cartId",
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: "cartNo",
          headerText: "카트번호",
          width: 80,
          textAlign: "left",
          type: "string",
        },
        {
          field: "caddieName",
          headerText: "캐디명",
          width: 120,
          textAlign: "left",
          type: "string",
        },
        {
          field: "cartName",
          headerText: "카트명",
          width: 120,
          textAlign: "left",
          type: "string",
        },
        {
          field: "cartKind",
          headerText: "카트종류",
          width: 90,
          textAlign: "left",
          isCommonCodeField: true,
          groupCode: "CART_KIND",
          type: "string",
        },
        {
          field: "modelName",
          headerText: "모델명",
          width: 120,
          textAlign: "left",
          type: "string",
        },
        {
          field: "cartStatus",
          headerText: "카트상태",
          width: 90,
          textAlign: "left",
          isCommonCodeField: true,
          groupCode: "CART_STATUS",
          type: "string",
        },
        {
          field: "dsuseFlag",
          headerText: "폐기여부",
          width: 90,
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "center",
        },
      ],
    };
  },
  created: function () {
    this.cartInfoOption.cartKindCodesOptions = commonCodesGetCommonCode(
      "CART_KIND"
    );
    if (
      this.cartInfoOption.cartKindCodesOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.cartInfoOption.cartKindCodesOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    this.cartInfoOption.cartKind = "ALL";
  },
  methods: {
    valueAccessor(field, data, column) {
      let code = data[field];
      let returnCode = null;
      if (code != null) {
        returnCode = commonCodesGetComName(column.comCode, code);
      }
      return returnCode;
    },
    showPopup: function (popupData) {
      this.cartInfoOption.cartNo = popupData;

      if (popupData) {
        this.getCartInfoList();
      }

      this.$refs.cartSearchPopup.show();
    },
    setZIndex: function (zIndex) {
      this.currentzIndex = zIndex;
    },
    dialogClose: function () {
      this.$emit("popupEvent", "popupClosed", this.cartInfo, this.$props.value);
    },
    onDialogClicked: function () {
      this.$emit(
        "popupEvent",
        "popupClicked",
        this.$props.popupKey,
        this.$props.value
      );
    },
    cartSearchPopupClose: function () {
      this.$refs.cartSearchPopup.hide();
    },
    onCartConfirmButton: function () {
      const row = this.$refs.cartInfoListGrid.getSelectedRecords();

      if (!(row.length > 0)) {
        this.errorToast("선택된 카트 정보가 존재하지 않습니다");
        return;
      }

      this.cartInfo.cartId = row[0].cartId;
      this.cartInfo.cartNo = row[0].cartNo;

      this.cartSearchPopupClose();
    },
    getCartInfoList: function () {
      const cartKind =
        this.cartInfoOption.cartKind === "ALL"
          ? null
          : this.cartInfoOption.cartKind;
      const cartNo =
        this.cartInfoOption.cartNo === "" ? null : this.cartInfoOption.cartNo;
      const dsuseFlag = this.cartInfoOption.dsuseFlag === true ? false : null;

      getCartInfoList(cartKind, cartNo, dsuseFlag)
        .then((response) => {
          console.log("getCartInfoList.===>", response);
          this.cartInfoList = response.value.cartInfoList;
        })
        .catch((error) => {
          console.log("getCartInfoList.err.===>", error);
        });
    },
    onCartNoTextBoxKeyDownEnter() {
      this.getCartInfoList();
    },
    onCartInfoListGridDataBound() {
      if (this.cartInfoList.length > 0) {
        this.$refs.cartInfoListGrid.selectRow(0);
      } else {
        document.getElementById("cartNoTextBox").select();
      }
    },
    onCartInfoListGridDoubleClicked() {
      this.onCartConfirmButton();
    },
  },
};
</script>
