import request from "@/utils/request";

/**
 * @return Promise
 */
export function getCartInfoList(cartKind, cartNo, dsuseFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/cart/info/list",
    method: "get",
    params: {
      cartKind: cartKind,
      cartNo: cartNo,
      dsuseFlag: dsuseFlag,
    },
  });
}
