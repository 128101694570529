<template>
  <div>
    <ejs-dialog
        ref="caddieInfoDetailPopup"
        :header="`캐디 근무확인서 ${headerInfo}`"
        :animationSettings="{ effect: 'None' }"
        :allowDragging="true"
        :showCloseIcon="true"
        :enableResize="true"
        :isModal="true"
        :visible="true"
        :close="dialogClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">발행일자</div>
                  <ul class="content">
                    <li class="item date">
                      <input-date
                          format="YYYY-MM-DD"
                          v-model="bsnDate"
                          type="lookupDetail-condition"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">사진 출력</div>
                  <ul class="content">
                    <li class="item check">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                                type="checkbox"
                                id="isOutputPhoto"
                                v-model="isOutputPhoto"
                            />
                            <i/>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">회사 CI</div>
                  <ul class="content">
                    <li class="item check">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                                type="checkbox"
                                id="isOutputCI"
                                v-model="isOutputCI"
                            />
                            <i/>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">용도</div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                          v-model="usage"
                          placeholder="발급용도"
                          @keydown.native="formPreventEnterEvent"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">발급부서</div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                          v-model="issuingDepartment"
                          @keydown.native="formPreventEnterEvent"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">연락처</div>
                  <ul class="content">
                    <li class="item">
                      <input-telephone
                          placeholder="숫자만 입력"
                          v-model="contactTel"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="print">
              <erp-button
                  button-div="PRINT"
                  :is-shortcut-button="true"
                  @click.native="onClickPrint">
                인쇄
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="dialogClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import commonMixin from '@/views/layout/mixin/commonMixin';
import {commonCodesGetCommonCode} from '@/utils/commonCodes';
import {DATE_FORMAT_YYYY_MM_DD} from '@/utils/date';
import {getCaddieInfoDetail} from '@/api/caddieInfoRegistration';
import {formPreventEnterEvent} from '@/utils/formUtil';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import InputText from '@/components/common/text/InputText';
import InputDate from '@/components/common/datetime/InputDate';
import ErpButton from "@/components/button/ErpButton.vue";
import InputTelephone from '@/components/common/text/InputTelephone';
import GolfERPService from '@/service/GolfERPService';
import {getBizInfo} from '@/api/bizCodeManagement';
import {getCurrentBizCode} from '@/utils/pageKeyUtil';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'CaddieInfoCertificationPopup',
  components: {
    InputText,
    InputDate,
    InputTelephone,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      bsnDate: null,
      bizAddr: null,
      imageCtpnURL: null,
      isOutputPhoto: true,
      isOutputCI: true,
      usage: '',
      issuingDepartment: '',
      contactTel: '',
      animationSettings: {effect: 'None'},
      caddieList: [],
      caddieInfoOption: {
        sexCodeCodesOptions: commonCodesGetCommonCode('SEX_CODE', true),
        caddieEmployDivCodesOptions: commonCodesGetCommonCode(
            'CADDIE_EMPLOY_DIV',
            true,
        ),
        caddieGroupDivCodesOptions: commonCodesGetCommonCode(
            'CADDIE_GROUP_DIV',
            true,
        ),
        caddieWorkDivCodesOptions: commonCodesGetCommonCode(
            'CADDIE_WORK_DIV',
            true,
        ),
        caddieGradeCodesOptions: commonCodesGetCommonCode('CADDIE_GRADE', true),
        caddieFeeDivCodesOptions: commonCodesGetCommonCode(
            'CADDIE_FEE_DIV',
            true,
        ),
      },
    };
  },
  async created() {
    const nowMoment = await GolfERPService.fetchNow();
    this.bsnDate = moment(nowMoment).format(DATE_FORMAT_YYYY_MM_DD);
    const bizCode = getCurrentBizCode();
    const {
      value: {tsBizMstList},
    } = await getBizInfo('bizCode', bizCode);
    const bizAddr = tsBizMstList.find(item => item.bizCode === bizCode);
    if (bizAddr) {
      this.bizAddr = `${bizAddr.addr1} ${bizAddr.addr2 || ''}`;
    }
    const info = sessionStorage.getItem('Standard-Infos');
    this.imageCtpnURL = JSON.parse(info)?.imageCtpnURL;
  },
  computed: {
    ...mapGetters(["isStopProgress"]),
    headerInfo() {
      const len = this.caddieList.length;
      const firstCaddieInfo = len > 0
        ? this.caddieList[0]
        : null;
      const suffix = len > 1
        ? ` 외 ${len - 1}건`
        : '';
      return firstCaddieInfo
        ? ` - ${firstCaddieInfo.caddieName} (${firstCaddieInfo.caddieNo})${suffix}`
        : '';
    },
  },
  methods: {
    ...mapActions(['setLoaderProgress', 'clearLoaderProgress']),
    formPreventEnterEvent,
    async fetchCaddieDetail(caddieIdList) {
      const len = caddieIdList.length;
      const putCaddieList = async (index = 0) => {
        if (this.isStopProgress || index === len) {
          if (this.isStopProgress) {
            this.dialogClose();
          }
          await this.clearLoaderProgress();
          return;
        }
        await this.setLoaderProgress({
          progress: index,
          maxProgress: len,
        });
        const caddieId = caddieIdList[index];
        const {value: data} = await getCaddieInfoDetail(caddieId);
        this.caddieList.push(data.caddieDetail);
        await putCaddieList(index + 1);
      };
      await putCaddieList();
    },
    async showPopup(caddieIdList = []) {
      if (caddieIdList.length < 1) {
        this.$refs.caddieName.focus();
      }
      try {
        await this.fetchCaddieDetail(caddieIdList);
      } catch (error) {
        this.$refs.caddieInfoDetailPopup.hide();
        throw error;
      }
    },
    onClickPrint() {
      const makePeriod = date => moment(date).format('YYYY년 MM월 DD일');
      const output = JSON.parse(JSON.stringify(this.caddieList))
        ?.map(item => {
          const entryDate = makePeriod(item.entryDate);
          const retireDate = makePeriod(item.retireDate || this.bsnDate);
          item.bsnDate = makePeriod(this.bsnDate);
          item.bizAddr = this.bizAddr;
          item.imageCtpnURL = this.isOutputCI && this.imageCtpnURL;
          item.imagePath = this.isOutputPhoto && item.imagePath;
          item.birthDay = makePeriod(item.birthDay);
          item.homeAddr = `${item.homeAddr1 || ''} ${item.homeAddr2 || ''}`;
          item.entryDate = entryDate;
          item.retireDate = retireDate;
          item.employmentPeriod = `${entryDate} ~ ${retireDate}`;
          item.usage = this.usage;
          item.issuingDepartment = this.issuingDepartment;
          item.contactTel = this.contactTel;
          return item;
        });
      this.$emit('print', output);
    },
    dialogClose() {
      this.$emit('popupClosed');
    },
    caddieInfoDetailPopupClose() {
      this.$refs.caddieInfoDetailPopup.hide();
    },
  },
};
</script>
