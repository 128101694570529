<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field caddyNameNumber">
            <div class="title">캐디명/번호</div>
            <ul class="content">
              <li class="item">
                <input-text
                  id="caddieNameNo"
                  v-model="caddieInfoSearchOption.caddieNameNo"
                  @focus="caddieNameNoClear"
                  @keydown.native="onCaddieNameNoKeyDown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item check">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="radio"
                        id="caddieEmployDivEmploy"
                        v-model="caddieInfoSearchOption.caddieEmployDiv"
                        value="EMPLOY"
                      />
                      <i></i>
                      <div class="label">재직</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="radio"
                        id="caddieEmployDivRetire"
                        v-model="caddieInfoSearchOption.caddieEmployDiv"
                        value="RETIRE"
                      />
                      <i></i>
                      <div class="label">퇴직</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              v-on:click.native="searchDetailPopupOpen"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true"/>
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">캐디 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="caddieInfoRegistration.shortcuts.add"
                      :shortcut="{key: 'F3'}"
                      v-on:click.native="caddieInfoDetailPopupOpen"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      @click.native="onClickPrint"
                  >
                    근무확인서
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      :ignore="isPopupOpened"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
              ref="caddieInfoListGrid"
              :provides="caddieInfoListGridOptions.provides"
              :columns="caddieInfoListGridOptions.columns"
              :dataSource="caddieInfoList"
              :rowHeight="24"
              :gridLines="'Both'"
              :width="'100%'"
              :height="'100%'"
              :enableVirtualization="false"
              :allowResizing="true"
              :allowSelection="true"
              :allowPaging="true"
              :allowExcelExport="true"
              :pageSettings="caddieInfoListGridOptions.pageSettings"
              :selectionSettings="caddieInfoListGridOptions.selectionSettings"
              :editSettings="caddieInfoListGridOptions.editSettings"
              @queryCellInfo="caddieInfoListGridQueryCellInfo"
              @gridCheckboxChanged="caddieInfoListGridCheckboxChanged"
              @recordClick="caddieInfoListGridClicked"
              @headerCellInfo="caddieInfoListHeaderCellInfo"
              @headerSelectAllCheckboxClicked="onHeaderSelectAllCheckboxClicked"
              @actionComplete="caddieInfoListGridActionComplete"
            />
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
      ref="searchDetailPopup"
      :header="`상세검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="387"
      :animationSettings="{ effect: 'None' }"
      :isModal="false"
      :visible="false"
      enableResize="true"
      v-show="isSearchDetailPopupOpen"
    >
      <div class="window lookupDetail-caddieInfoRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">조구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="caddieGroupDivDropdown"
                        v-model="caddieInfoSearchOption.caddieGroupDiv"
                        :dataSource="
                          caddieInfoSearchOption.caddieGroupDivCodesOptions
                        "
                        :allowFiltering="false"
                        :fields="{ text: 'comName', value: 'comCode' }"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">근무구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="caddieWorkDivDropdown"
                        v-model="caddieInfoSearchOption.caddieWorkDiv"
                        :dataSource="
                          caddieInfoSearchOption.caddieWorkDivCodesOptions
                        "
                        :allowFiltering="false"
                        :fields="{ text: 'comName', value: 'comCode' }"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">캐디등급</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="caddieGradeDropdown"
                        v-model="caddieInfoSearchOption.caddieGrade"
                        :dataSource="
                          caddieInfoSearchOption.caddieGradeCodesOptions
                        "
                        :allowFiltering="false"
                        :fields="{ text: 'comName', value: 'comCode' }"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">캐디피구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="caddieFeeDivDropdown"
                        v-model="caddieInfoSearchOption.caddieFeeDiv"
                        :dataSource="
                          caddieInfoSearchOption.caddieFeeDivCodesOptions
                        "
                        :allowFiltering="false"
                        :fields="{ text: 'comName', value: 'comCode' }"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">지정카트</div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                        ref="appnCartSearchOption"
                        v-model="caddieInfoSearchOption.appnCart"
                        placeholder="카트번호"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">생년월</div>
                  <ul class="content">
                    <li class="item">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              v-model="caddieInfoSearchOption.birthMonthFlag"
                            />
                            <i></i>
                            <div class="label">사용</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                    <li class="item date">
                      <input-date
                        format="YYYY-MM"
                        :disabled="!caddieInfoSearchOption.birthMonthFlag"
                        v-model="caddieInfoSearchOption.birthMonth"
                        type="lookupDetail-condition"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">성별</div>
                  <ul class="content">
                    <li class="item check">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              id="sexCodeM"
                              v-model="caddieInfoSearchOption.sexCodeM"
                            />
                            <i></i>
                            <div class="label">남</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              id="sexCodeF"
                              v-model="caddieInfoSearchOption.sexCodeF"
                            />
                            <i></i>
                            <div class="label">여</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">입직신고여부</div>
                  <ul class="content">
                    <li class="item check">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                                type="radio"
                                id="employStatementFlagAll"
                                v-model="caddieInfoSearchOption.employStatementFlag"
                                :value="null"
                            />
                            <i></i>
                            <div class="label">전체</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                                type="radio"
                                id="employStatementFlagTrue"
                                v-model="caddieInfoSearchOption.employStatementFlag"
                                :value="'true'"
                            />
                            <i></i>
                            <div class="label">신고</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                                type="radio"
                                id="employStatementFlagFalse"
                                v-model="caddieInfoSearchOption.employStatementFlag"
                                :value="'false'"
                            />
                            <i></i>
                            <div class="label">미신고</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">이직신고여부</div>
                  <ul class="content">
                    <li class="item check">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                                type="radio"
                                id="lflyStatementFlagAll"
                                v-model="caddieInfoSearchOption.lflyStatementFlag"
                                :value="null"
                            />
                            <i></i>
                            <div class="label">전체</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                                type="radio"
                                id="lflyStatementFlagTrue"
                                v-model="caddieInfoSearchOption.lflyStatementFlag"
                                :value="'true'"
                            />
                            <i></i>
                            <div class="label">신고</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                                type="radio"
                                id="lflyStatementFlagFalse"
                                v-model="caddieInfoSearchOption.lflyStatementFlag"
                                :value="'false'"
                            />
                            <i></i>
                            <div class="label">미신고</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                button-div="GET"
                :ignore="isPopupOpened"
                :is-shortcut-button="true"
                v-on:click.native="onViewButtonClicked"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  v-on:click.native="searchDetailInit">
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  v-on:click.native="searchDetailPopupClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <caddie-info-detail-popup
      v-if="isCaddieInfoDetailPopupOpen"
      ref="caddieInfoDetailPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      popupKey=""
      @add="onCaddieInfoDetailPopupAdd"
      @popupEvent="onCaddieInfoDetailPopupEvent"
    />
    <caddie-info-certification-popup
      v-if="isCaddieInfoCertificationPopup"
      ref="caddieInfoCertificationPopup"
      @print="print"
      @popupClosed="onCaddieInfoCertificationPopupClosed"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.caddyNameNumber .content .item {width: 120px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import Vue from "vue";
import {
  Edit,
  Resize,
  ForeignKey,
  Page,
  ExcelExport,
} from "@syncfusion/ej2-vue-grids";
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  commonCodesGetCommonCode,
  commonCodeGetComCodeDefaultValue,
} from "@/utils/commonCodes";
import { getFormattedDate } from "@/utils/date";
import { getCaddieInfoList } from "@/api/caddieInfoRegistration";
import ReportView from '@/components/common/report/ReportView';
import CaddieInfoDetailPopup from "../popup/CaddieInfoDetailPopup";
import CaddieInfoCertificationPopup from "../popup/CaddieInfoCertificationPopup";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDate from "@/components/common/datetime/InputDate";
import { gridUtilGetTelColumnAccess } from "@/utils/gridUtil";
import { numberWithCommas } from "@/utils/number";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import ErpButton from "@/components/button/ErpButton.vue";
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';

export default {
  name: "caddieInfoRegistration",
  components: {
    InputText,
    InputDate,
    ReportView,
    CaddieInfoDetailPopup,
    CaddieInfoCertificationPopup,
    EjsGridWrapper,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  data() {
    return {
      count: 0,
      caddieInfoListLength: 0,
      isSearchDetailPopupOpen: false,
      isCaddieInfoDetailPopupOpen: false,
      isCaddieInfoCertificationPopup: false,
      caddieInfoList: [],
      caddieIdList: [],
      caddieInfo: {
        caddieId: null,
      },
      caddieInfoSearchOption: {
        caddieNameNo: null,
        caddieEmployDivCodesOptions: [],
        caddieEmployDiv: null,
        caddieGroupDivCodesOptions: [],
        caddieGroupDiv: null,
        caddieWorkDivCodesOptions: [],
        caddieWorkDiv: null,
        caddieGradeCodesOptions: [],
        caddieGrade: null,
        caddieFeeDivCodesOptions: [],
        caddieFeeDiv: null,
        appnCart: null,
        birthMonthFlag: false,
        birthMonth: null,
        sexCodeM: true,
        sexCodeF: true,
        employStatementFlag: null,
        lflyStatementFlag: null
      },
      caddieInfoListGridOptions: {
        provides: [Edit, Resize, ForeignKey, Page, ExcelExport],
        selectionSettings: { type: "Single", mode: "Both", enableToggle: false },
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        pageSettings: { pageSize: 50 },
        columns: [
          {
            field: "caddieId",
            allowEditing: false,
            type: "number",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "select",
            headerText: "출력",
            allowEditing: true,
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            minWidth: 16,
            width: 60,
            textAlign: "center",
            isSelectAllColumn: true,
          },
          {
            field: "caddieNo",
            headerText: "캐디번호",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "caddieName",
            headerText: "캐디명",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "left",
          },
          {
            field: "sexCode",
            headerText: "성별",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "SEX_CODE",
          },
          {
            field: "argmtSno",
            headerText: "배치순번",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isNumericType: true,
          },
          {
            field: "caddieGroupDiv",
            headerText: "조번호",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "CADDIE_GROUP_DIV",
          },
          {
            field: "caddieWorkDiv",
            headerText: "근무 구분",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "CADDIE_WORK_DIV",
          },
          {
            field: "caddieGrade",
            headerText: "등급",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "CADDIE_GRADE",
          },
          {
            field: "caddieFeeDiv",
            headerText: "캐디피 구분",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 120,
            textAlign: "center",
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "CADDIE_FEE_DIV",
          },
          {
            field: "hpNo",
            headerText: "연락처",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 110,
            textAlign: "center",
            valueAccessor: gridUtilGetTelColumnAccess,
          },
          {
            field: "cartNo",
            headerText: "카트번호",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "birthDay",
            headerText: "생년월일",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isDateType: true,
          },
          {
            field: "caddieEmployDiv",
            headerText: "재직구분",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "CADDIE_EMPLOY_DIV",
          },
          {
            field: "finalAcdmcrDiv",
            headerText: "학력정보",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "ACDMCR_DIV",
          },
          {
            field: "caddieCareerInfo",
            headerText: "경력정보",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 180,
          },
          {
            field: "entryDate",
            headerText: "입사일자",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isDateType: true,
          },
          {
            field: "retireDate",
            headerText: "퇴사일자",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isDateType: true,
          },
          {
            field: "reEntryFlag",
            headerText: "재입사",
            allowEditing: false,
            type: "boolean",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            editType: "dropdownedit",
            displayAsCheckBox: true
          },
          {
            field: "employStatementFlag",
            headerText: "입직신고여부",
            allowEditing: false,
            type: "boolean",
            minWidth: 16,
            width: 110,
            textAlign: "center",
            displayAsCheckBox: true
          },
          {
            field: "lflyStatementFlag",
            headerText: "이직신고여부",
            allowEditing: false,
            type: "boolean",
            minWidth: 16,
            width: 110,
            textAlign: "center",
            displayAsCheckBox: true
          },
          {
            field: "iaEmployJoinDate",
            headerText: "산재고용가입일자",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 130,
            textAlign: "center",
            isDateType: true,
          },
          {
            field: "iaEmployCancelDate",
            headerText: "산재고용해지일자",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 130,
            textAlign: "center",
            isDateType: true,
          },
          {
            field: "workingYears",
            headerText: "근무년수",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "homePostNo",
            headerText: "우편번호",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "homeAddr1",
            headerText: "주소1",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 240,
            textAlign: "left",
          },
          {
            field: "homeAddr2",
            headerText: "주소2",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 240,
            textAlign: "left",
          },
          {
            field: "remarks",
            headerText: "비고",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 240,
            textAlign: "left",
          },
        ],
      },
      numberTemplate() {
        return {
          template: Vue.component("editOption", {
            template: "<span>{{parseInt(data.index) + 1}}</span>",
            data() {
              return {
                data: {
                  data: {},
                },
              };
            },
          }),
        };
      },
    };
  },
  computed: {
    isPopupOpened() {
      return (
          this.isCaddieInfoDetailPopupOpen ||
          this.isCaddieInfoCertificationPopup
      );
    },

  },
  created() {
    this.caddieInfoSearchOption.caddieEmployDivCodesOptions = commonCodesGetCommonCode(
      "CADDIE_EMPLOY_DIV",
      true
    );
    if (
      this.caddieInfoSearchOption.caddieEmployDivCodesOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.caddieInfoSearchOption.caddieEmployDivCodesOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    this.caddieInfoSearchOption.caddieEmployDiv = commonCodeGetComCodeDefaultValue(
      "CADDIE_EMPLOY_DIV"
    );

    this.caddieInfoSearchOption.caddieGroupDivCodesOptions = commonCodesGetCommonCode(
      "CADDIE_GROUP_DIV",
      true
    );
    if (
      this.caddieInfoSearchOption.caddieGroupDivCodesOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.caddieInfoSearchOption.caddieGroupDivCodesOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    this.caddieInfoSearchOption.caddieGroupDiv = "ALL";

    this.caddieInfoSearchOption.caddieWorkDivCodesOptions = commonCodesGetCommonCode(
      "CADDIE_WORK_DIV",
      true
    );
    if (
      this.caddieInfoSearchOption.caddieWorkDivCodesOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.caddieInfoSearchOption.caddieWorkDivCodesOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    this.caddieInfoSearchOption.caddieWorkDiv = "ALL";

    this.caddieInfoSearchOption.caddieGradeCodesOptions = commonCodesGetCommonCode(
      "CADDIE_GRADE",
      true
    );
    if (
      this.caddieInfoSearchOption.caddieGradeCodesOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.caddieInfoSearchOption.caddieGradeCodesOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    this.caddieInfoSearchOption.caddieGrade = "ALL";

    this.caddieInfoSearchOption.caddieFeeDivCodesOptions = commonCodesGetCommonCode(
      "CADDIE_FEE_DIV",
      true
    );
    if (
      this.caddieInfoSearchOption.caddieFeeDivCodesOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.caddieInfoSearchOption.caddieFeeDivCodesOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    this.caddieInfoSearchOption.caddieFeeDiv = "ALL";
  },
  mounted() {
    this.getCaddieInfoList();
  },
  watch: {
    $route() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
  },
  methods: {
    numberWithCommas,
    caddieInfoListHeaderCellInfo(args) {
      const {
        cell: {column: {headerText, field}},
        node
      } = args;
      if (headerText === "NO") {
        node.classList.add(this.$t("className.grid.noSortFilter"));
      }
      if ([
        'caddieNo',
        'caddieName'
      ].includes(field)) {
        node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    caddieInfoListGridQueryCellInfo(args) {
      const {
        column: {field},
          cell
      } = args;
      if ([
        'caddieNo',
        'caddieName'
      ].includes(field)) {
        cell.classList.add(this.$t("className.grid.clickArea"));
      }
      if (field === "select") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    async caddieInfoListGridCheckboxChanged(args) {
      const {
        columnName: field,
        rowData
      } = args;
      if (field === "select") {
        const hasCaddieId = this.caddieIdList
          ?.findIndex(item => item === rowData.caddieId);
        const findIndex = this.caddieInfoList
          ?.findIndex(item => item.caddieId === rowData.caddieId);
        if (findIndex > -1) {
          if (hasCaddieId > -1) {
            this.caddieInfoList[findIndex].select = false;
            delete this.caddieIdList[hasCaddieId];
            this.caddieIdList = this.caddieIdList
              ?.filter(item => !!item);
          } else {
            this.caddieInfoList[findIndex].select = true;
            this.caddieIdList.push(rowData.caddieId);
          }
          this.$forceUpdate();
        }
      }
    },
    onHeaderSelectAllCheckboxClicked(args) {
      const {
        field,
        value: flag,
      } = args;
      if (field === "select") {
        this.caddieIdList = flag
          ? this.caddieInfoList
              ?.map(item => item.caddieId)
          : [];
        this.caddieInfoList
          ?.map(item => {
            item.select = !!flag;
            return item;
          });
      }
    },
    async caddieInfoListGridClicked(args) {
      const {
        column: {
          field
        },
        rowData
      } = args;
      if (field === "caddieNo" || field === "caddieName") {
        this.isCaddieInfoDetailPopupOpen = true; // 팝업을 띄울 때 생성되도록 하기 위함
        await this.$nextTick();
        this.$refs.caddieInfoDetailPopup.showPopup(rowData.caddieId);
      }
    },
    onBasePopupEvent(eventId) {
      if (eventId === "popupClosed") {
        this.onViewButtonClicked();
      }
    },
    async onCaddieInfoDetailPopupAdd() {
      this.isCaddieInfoDetailPopupOpen = false;
      await this.$nextTick();
      await this.caddieInfoDetailPopupOpen();
    },
    onCaddieInfoDetailPopupEvent(eventId) {
      if (eventId === "popupClosed") {
        this.isCaddieInfoDetailPopupOpen = false;
        this.onViewButtonClicked();
      }
    },
    async caddieInfoDetailPopupOpen() {
      this.caddieInfo.caddieId = null;
      this.isCaddieInfoDetailPopupOpen = true;
      await this.$nextTick();
      this.$refs.caddieInfoDetailPopup.showPopup();
    },
    onCaddieInfoCertificationPopupClosed() {
      this.isCaddieInfoCertificationPopup = false;
    },
    caddieNameNoClear() {
      this.caddieInfoSearchOption.caddieNameNo = null;
    },
    onCaddieNameNoKeyDown(event) {
      if (event.code === "Enter") {
        this.getCaddieInfoList();
      }
    },
    searchDetailInit() {
      this.caddieInfoSearchOption.caddieEmployDiv = "ALL";
      this.caddieInfoSearchOption.caddieGroupDiv = "ALL";
      this.caddieInfoSearchOption.caddieWorkDiv = "ALL";
      this.caddieInfoSearchOption.caddieGrade = "ALL";
      this.caddieInfoSearchOption.caddieFeeDiv = "ALL";
      this.caddieInfoSearchOption.appnCart = null;
      this.caddieInfoSearchOption.birthMonthFlag = false;
      this.caddieInfoSearchOption.birthMonth = null;
      this.caddieInfoSearchOption.sexCodeM = true;
      this.caddieInfoSearchOption.sexCodeF = true;
      this.caddieInfoSearchOption.employStatementFlag = null;
      this.caddieInfoSearchOption.lflyStatementFlag = null;
    },
    onViewButtonClicked() {
      this.getCaddieInfoList();
    },
    searchDetailPopupOpen() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();
    },
    searchDetailPopupClose() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    getCaddieInfoList() {
      const caddieNameNo = this.caddieInfoSearchOption.caddieNameNo;
      const caddieEmployDiv =
        this.caddieInfoSearchOption.caddieEmployDiv === "ALL"
          ? null
          : this.caddieInfoSearchOption.caddieEmployDiv;
      const caddieGroupDiv =
        this.caddieInfoSearchOption.caddieGroupDiv === "ALL"
          ? null
          : this.caddieInfoSearchOption.caddieGroupDiv;
      const caddieWorkDiv =
        this.caddieInfoSearchOption.caddieWorkDiv === "ALL"
          ? null
          : this.caddieInfoSearchOption.caddieWorkDiv;
      const caddieGrade =
        this.caddieInfoSearchOption.caddieGrade === "ALL"
          ? null
          : this.caddieInfoSearchOption.caddieGrade;
      const caddieFeeDiv =
        this.caddieInfoSearchOption.caddieFeeDiv === "ALL"
          ? null
          : this.caddieInfoSearchOption.caddieFeeDiv;
      const appnCart = this.caddieInfoSearchOption.appnCart;
      const sexCodeM = this.caddieInfoSearchOption.sexCodeM;
      const sexCodeF = this.caddieInfoSearchOption.sexCodeF;
      const birthMonthFlag = this.caddieInfoSearchOption.birthMonthFlag;
      const employStatementFlag = this.caddieInfoSearchOption.employStatementFlag;
      const lflyStatementFlag = this.caddieInfoSearchOption.lflyStatementFlag;
      let birthMonth = this.caddieInfoSearchOption.birthMonth;
      let sexCodes = null;

      if (birthMonthFlag !== true) {
        birthMonth = null;
      }

      if (birthMonth != null) {
        birthMonth = getFormattedDate(birthMonth);
      }
      if (sexCodeM === true && sexCodeF === true) {
        sexCodes = null;
      } else if (sexCodeM === true) {
        sexCodes = "M";
      } else if (sexCodeF === true) {
        sexCodes = "F";
      } else {
        sexCodes = null;
      }

      getCaddieInfoList(
        caddieNameNo,
        caddieEmployDiv,
        caddieGroupDiv,
        caddieWorkDiv,
        caddieGrade,
        caddieFeeDiv,
        appnCart,
        birthMonth,
        sexCodes,
        birthMonthFlag,
        employStatementFlag,
        lflyStatementFlag
      )
        .then(response => {
          this.caddieIdList = [];
          this.caddieInfoList = response.value.caddieMstInfoList
            ?.map(item => {
              item.select = false;
              return item;
            });
          this.caddieInfoListLength = response.value.caddieMstInfoList.length;
        })
        .catch(error => {
          console.log(error);
        });
    },
    caddieInfoListGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.caddieInfoListGrid?.getGridBatchCount() || 0
      );
    },
    async onClickPrint() {
      if (this.caddieIdList.length < 1) {
        this.errorToast("출력할 자료를 1건 이상 선택 바랍니다");
        return;
      }
      this.isCaddieInfoCertificationPopup = true;
      await this.$nextTick();
      this.$refs.caddieInfoCertificationPopup?.showPopup(this.caddieIdList);
    },
    print(data) {
      const reportData = {
        reportJson: {
          jsonData: data,
        },
      };
      this.$refs.reportViewComponent.postReport(reportData, 'CertificateOfEmployment');
    },
    onClickExcel() {
      this.$refs.caddieInfoListGrid.excelExport();
    },
  },
};
</script>
