<template>
  <div>
    <ejs-dialog
      ref="caddieInfoDetailPopup"
      header="캐디정보 상세"
      :animationSettings="animationSettings"
      :allowDragging="true"
      :showCloseIcon="true"
      width="1200"
      height="800"
      :close="dialogClose"
      v-on:mousedown.native="onDialogClicked"
      :position="popupPosition"
      :zIndex="currentzIndex"
      :isModal="$props.isModal"
      :visible="isVisible"
      :cssClass="
        caddieInfo.caddieId
          ? $t('className.popup.productCodePopupModify')
          : $t('className.popup.productCodePopupAdd')
      "
    >
      <div class="window caddieInformation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">캐디정보 상세</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              :ignore="isPopupOpened"
                              :is-icon-custom="true"
                              :is-custom-shortcut-button="true"
                              shortcut-key="caddieInfoDetailPopup.shortcuts.add"
                              :shortcut="{key: 'F3'}"
                             @click.native="caddieInfoInitButtonClick"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              :ignore="isPopupOpened"
                              :is-shortcut-button="true"
                              @click.native="onDeleteButtonClicked"
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article">
                      <section class="article-section section-0101">
                        <div class="section-body">
                          <article class="body-article">
                            <div class="article-left">
                              <!-- 아코디언 : accordion / 닫힘 : close -->
                              <section
                                :class="[
                                  'article-section',
                                  'section-010101',
                                  'accordion',
                                  { close: !isCaddieDefaultVisible },
                                ]"
                              >
                                <div class="section-header">
                                  <div class="header-left">
                                    <div
                                      class="header-title"
                                      @click="isCaddieDefaultVisible = !isCaddieDefaultVisible"
                                    >
                                      캐디 기본정보
                                    </div>
                                  </div>
                                </div>
                                <div class="section-body">
                                  <div class="body-data">
                                    <div class="data-outer">
                                      <div class="data-inner">
                                        <ul class="data-content">
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">캐디명</div>
                                            <div class="content">
                                              <ul class="row" style="border-bottom: none;">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                      ref="caddieName"
                                                      v-model="caddieInfo.caddieName"
                                                      @blur="onCaddieNameBlur"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field dateOfBirth">
                                            <!-- 필수 : required -->
                                            <div class="title required">생년월일</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group birthday">
                                                  <div class="form">
                                                    <input-birthday
                                                      ref="birthDay"
                                                      type="body-data"
                                                      v-model="caddieInfo.birthDay"
                                                    />
                                                  </div>
                                                </li>
                                                <li class="item form-group check">
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                            type="radio"
                                                            id="lunarCodeSolar"
                                                            v-model="
                                                            caddieInfo.lunarCode
                                                          "
                                                            :value="'SOLAR'"
                                                        />
                                                        <i></i>
                                                        <div class="label">양력</div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                            type="radio"
                                                            id="lunarCodeLunar"
                                                            v-model="
                                                            caddieInfo.lunarCode
                                                          "
                                                            :value="'LUNAR'"
                                                        />
                                                        <i></i>
                                                        <div class="label">음력</div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">성별</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                        ref="sexCode"
                                                        v-model="caddieInfo.sexCode"
                                                        :dataSource="
                                                        caddieInfoOption.sexCodeCodesOptions
                                                      "
                                                        :allowFiltering="false"
                                                        :fields="{
                                                        text: 'comName',
                                                        value: 'comCode',
                                                      }"
                                                        cssClass="body-data-dropdown"
                                                    ></ejs-dropdownlist>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">입사일자</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-date
                                                        ref="entryDate"
                                                        type="body-data"
                                                        v-model="caddieInfo.entryDate"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">재입사여부</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group check">
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                            type="radio"
                                                            id="reEntryFlagFalse"
                                                            v-model="
                                                            caddieInfo.reEntryFlag
                                                          "
                                                            :value="'false'"
                                                        />
                                                        <i></i>
                                                        <div class="label">신규</div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                            type="radio"
                                                            id="reEntryFlagTrue"
                                                            v-model="
                                                            caddieInfo.reEntryFlag
                                                          "
                                                            :value="'true'"
                                                        />
                                                        <i></i>
                                                        <div class="label">재입사</div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">
                                              재직 구분
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                        ref="caddieEmployDiv"
                                                        v-model="
                                                        caddieInfo.caddieEmployDiv
                                                      "
                                                        :dataSource="
                                                        caddieInfoOption.caddieEmployDivCodesOptions
                                                      "
                                                        :allowFiltering="false"
                                                        :fields="{
                                                        text: 'comName',
                                                        value: 'comCode',
                                                      }"
                                                        cssClass="body-data-dropdown"
                                                        @change="
                                                        onCaddieEmployDivChange
                                                      "
                                                    ></ejs-dropdownlist>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div
                                                class="title"
                                                :class="
                                                caddieInfo.caddieEmployDiv ===
                                                'RETIRE'
                                                  ? 'required'
                                                  : ''
                                              "
                                            >
                                              퇴사일자
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-date
                                                        ref="retireDate"
                                                        v-model="caddieInfo.retireDate"
                                                        type="body-data"
                                                        :disabled="
                                                        caddieInfo.caddieEmployDiv !==
                                                        'RETIRE'
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">입직신고여부</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group check">
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                            type="radio"
                                                            id="employStatementFlagTrue"
                                                            v-model="
                                                            caddieInfo.employStatementFlag
                                                          "
                                                            :value="'true'"
                                                        />
                                                        <i></i>
                                                        <div class="label">신고</div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                            type="radio"
                                                            id="employStatementFlagFalse"
                                                            v-model="
                                                            caddieInfo.employStatementFlag
                                                          "
                                                            :value="'false'"
                                                        />
                                                        <i></i>
                                                        <div class="label">미신고</div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <div class="title">산재고용가입일자</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-date
                                                        ref="iaEmployJoinDate"
                                                        type="body-data"
                                                        v-model="caddieInfo.iaEmployJoinDate"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          <li class="field">
                                            <div class="title">산재고용해지일자</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-date
                                                        ref="iaEmployCancelDate"
                                                        type="body-data"
                                                        v-model="caddieInfo.iaEmployCancelDate"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">이직신고여부</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group check">
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                            type="radio"
                                                            id="lflyStatementFlagTrue"
                                                            v-model="
                                                            caddieInfo.lflyStatementFlag
                                                          "
                                                            :value="'true'"
                                                        />
                                                        <i></i>
                                                        <div class="label">신고</div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                            type="radio"
                                                            id="lflyStatementFlagFalse"
                                                            v-model="
                                                            caddieInfo.lflyStatementFlag
                                                          "
                                                            :value="'false'"
                                                        />
                                                        <i></i>
                                                        <div class="label">미신고</div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">결혼여부</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group check">
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                            type="radio"
                                                            id="marriFlagTrue"
                                                            v-model="
                                                            caddieInfo.marriFlag
                                                          "
                                                            :value="'true'"
                                                        />
                                                        <i></i>
                                                        <div class="label">기혼</div>
                                                      </label>
                                                    </li>
                                                    <li>
                                                      <label>
                                                        <input
                                                            type="radio"
                                                            id="marriFlagFalse"
                                                            v-model="
                                                            caddieInfo.marriFlag
                                                          "
                                                            :value="'false'"
                                                        />
                                                        <i></i>
                                                        <div class="label">미혼</div>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">연락처</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <component-telephone
                                                        id="hpNo"
                                                        name="hpNo"
                                                        v-model="caddieInfo.hpNo"
                                                        :max-length="11"
                                                        @keydown.native="
                                                        preventEnterEvent
                                                      "
                                                        @blur="onHpNoBlur"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">비상 연락처</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <component-telephone
                                                        id="homeTelNo"
                                                        name="homeTelNo"
                                                        v-model="caddieInfo.homeTelNo"
                                                        @keydown.native="
                                                        preventEnterEvent
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">차량종류</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group input">
                                                  <div class="form">
                                                    <input-text
                                                        id="vehicleKind"
                                                        v-model="caddieInfo.vehicleKind"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">차량번호</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group input">
                                                  <div class="form">
                                                    <input-text
                                                        id="vehicleNo"
                                                        v-model="caddieInfo.vehicleNo"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title">기숙사 번호</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group input">
                                                  <div class="form">
                                                    <input-text
                                                        id="brhsNo"
                                                        v-model="caddieInfo.brhsNo"
                                                        :maxlength="50"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field address">
                                            <!-- 필수 : required -->
                                            <div class="title">집 주소</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group post">
                                                  <div class="form">
                                                    <input-text
                                                        id="homePostNo"
                                                        v-model="caddieInfo.homePostNo"
                                                    />
                                                  </div>
                                                </li>
                                                <li class="item form-group button" style="width: 370px;">
                                                  <ul class="button">
                                                    <li>
                                                      <erp-button
                                                          button-div="GET"
                                                          :is-icon-custom="true"
                                                          @click.native="onPostCodeClick()"
                                                      >
                                                        우편번호
                                                      </erp-button>
                                                    </li>
                                                  </ul>
                                                </li>
                                                <li class="field" style="width: 269px; height: 24px;">
                                                  <!-- 필수 : required -->
                                                  <div class="title">가족 관계</div>
                                                  <div class="content" style="border-right: none;">
                                                    <ul class="row">
                                                      <li class="item form-group input" style="width: 100%;">
                                                        <div class="form">
                                                          <input-text
                                                              id="familyRelation"
                                                              v-model="caddieInfo.familyRelation"
                                                              :maxlength="50"
                                                          />
                                                        </div>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </li>
                                              </ul>
                                              <ul class="row">
                                                <li class="item form-group input">
                                                  <div class="form">
                                                    <input-text
                                                        id="homeAddr1"
                                                        v-model="caddieInfo.homeAddr1"
                                                    />
                                                  </div>
                                                </li>
                                                <li class="item form-group input">
                                                  <div class="form">
                                                    <input-text
                                                        id="homeAddr2"
                                                        v-model="caddieInfo.homeAddr2"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                        </ul>
                                        <div class="border-left"></div>
                                        <div class="border-right"></div>
                                        <div class="border-top"></div>
                                        <div class="border-bottom"></div>
                                      </div>
                                    </div>
                                    <div class="border-left"></div>
                                    <div class="border-right"></div>
                                    <div class="border-top"></div>
                                    <div class="border-bottom"></div>
                                  </div>
                                </div>
                              </section>
                              <!-- 아코디언 : accordion / 닫힘 : close -->
                              <section
                                :class="[
                                  'article-section',
                                  'section-010102',
                                  'accordion',
                                  { close: !isGameProgressVisible },
                                ]"
                              >
                                <div class="section-header">
                                  <div class="header-left">
                                    <div
                                      class="header-title"
                                      @click="isGameProgressVisible = !isGameProgressVisible"
                                    >
                                      경기진행 정보
                                    </div>
                                  </div>
                                </div>
                                <div class="section-body">
                                  <div class="body-data">
                                    <div class="data-outer">
                                      <div class="data-inner">
                                        <ul class="data-content">
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">캐디번호</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-text
                                                        id="caddieNo"
                                                        v-model="caddieInfo.caddieNo"
                                                        :disabled="
                                                        caddieInfo.caddieEmployDiv ===
                                                        'RETIRE'
                                                      "
                                                        :maxlength="5"
                                                        @blur="onCaddieNoBlur"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">배치순번</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-number
                                                        ref="argmtSno"
                                                        v-model="caddieInfo.argmtSno"
                                                        :min="0"
                                                        :propMaxLength="5"
                                                        :disabled="
                                                        caddieInfo.caddieEmployDiv ===
                                                        'RETIRE'
                                                      "
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">조 번호</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                        ref="caddieGroupDiv"
                                                        v-model="
                                                        caddieInfo.caddieGroupDiv
                                                      "
                                                        :dataSource="
                                                        caddieInfoOption.caddieGroupDivCodesOptions
                                                      "
                                                        :allowFiltering="false"
                                                        :fields="{
                                                        text: 'comName',
                                                        value: 'comCode',
                                                      }"
                                                        cssClass="body-data-dropdown"
                                                    ></ejs-dropdownlist>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">
                                              근무 구분
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                        ref="caddieWorkDiv"
                                                        v-model="
                                                        caddieInfo.caddieWorkDiv
                                                      "
                                                        :dataSource="
                                                        caddieInfoOption.caddieWorkDivCodesOptions
                                                      "
                                                        :allowFiltering="false"
                                                        :fields="{
                                                        text: 'comName',
                                                        value: 'comCode',
                                                      }"
                                                        cssClass="body-data-dropdown"
                                                    ></ejs-dropdownlist>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">등급</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                        ref="caddieGrade"
                                                        v-model="caddieInfo.caddieGrade"
                                                        :dataSource="
                                                        caddieInfoOption.caddieGradeCodesOptions
                                                      "
                                                        :allowFiltering="false"
                                                        :fields="{
                                                        text: 'comName',
                                                        value: 'comCode',
                                                      }"
                                                        cssClass="body-data-dropdown"
                                                    ></ejs-dropdownlist>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field">
                                            <!-- 필수 : required -->
                                            <div class="title required">
                                              캐디피 구분
                                            </div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <ejs-dropdownlist
                                                        ref="caddieFeeDiv"
                                                        v-model="
                                                        caddieInfo.caddieFeeDiv
                                                      "
                                                        :dataSource="
                                                        caddieInfoOption.caddieFeeDivCodesOptions
                                                      "
                                                        :allowFiltering="false"
                                                        :fields="{
                                                        text: 'comName',
                                                        value: 'comCode',
                                                      }"
                                                        cssClass="body-data-dropdown"
                                                    ></ejs-dropdownlist>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field cart">
                                            <!-- 필수 : required -->
                                            <div class="title">지정카트</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group input">
                                                  <div class="form">
                                                    <input-text
                                                        id="cartNo"
                                                        v-model="caddieInfo.cartNo"
                                                        @blur="onCartNoBlur"
                                                    />
                                                  </div>
                                                </li>
                                                <li class="item form-group button">
                                                  <ul class="button">
                                                    <li class="search">
                                                      <erp-button
                                                          button-div="GET"
                                                          v-on:click.native="cartSearchPopupOpen"
                                                      >
                                                        검색
                                                      </erp-button>
                                                    </li>
                                                  </ul>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field remarks">
                                            <!-- 필수 : required -->
                                            <div class="title">비고</div>
                                            <div class="content">
                                              <ul class="row">
                                                <li class="item form-group">
                                                  <div class="form">
                                                    <input-textarea
                                                        v-model="caddieInfo.remarks"
                                                    />
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                        </ul>
                                        <div class="border-left"></div>
                                        <div class="border-right"></div>
                                        <div class="border-top"></div>
                                        <div class="border-bottom"></div>
                                      </div>
                                    </div>
                                    <div class="border-left"></div>
                                    <div class="border-right"></div>
                                    <div class="border-top"></div>
                                    <div class="border-bottom"></div>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div class="article-right">
                              <!-- 아코디언 : accordion / 닫힘 : close -->
                              <section class="article-section section-010103">
                                <div class="section-header">
                                  <div class="header-left">
                                    <div class="header-title">캐디 사진</div>
                                  </div>
                                  <div class="header-right">
                                    <ul class="header-button">
                                      <li class="registration">
                                        <ejs-uploader
                                          ref="caddieFileUploader"
                                          name="caddieFileUploader"
                                          :buttons="buttons"
                                          :allowedExtensions="caddieFileUploaderExtensions"
                                          :multiple="false"
                                          maxFileSize="10000000"
                                          :selected="onFileSelect"
                                        />
                                      </li>
                                      <li class="delete">
                                        <erp-button
                                            button-div="DELETE"
                                            :is-icon-custom="true"
                                          @click.native="clearCaddieFiles"
                                        >
                                          삭제
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="section-body">
                                  <div
                                    class="body-photo"
                                    :id="`imagePreviewContainer_${caddieInfo.caddieId}`"
                                  >
                                    <!-- 업로드 이미지 conatiner -->
                                    <!-- 기존 이미지 영역 TODO : v-if로 이미지가 없는 경우 출력 안함. url에 업로드된 URL을 넣어 줌 -->
                                    <div
                                      v-if="caddieInfo.imagePath"
                                      class="item"
                                      id="previewImage"
                                      :style="{ backgroundImage: 'url(' + caddieInfo.imagePath + ')' }"
                                    />
                                  </div>
                                </div>
                              </section>
                            </div>
                          </article>
                        </div>
                      </section>
                      <section class="article-section section-0102">
                        <div class="section-body">
                          <article class="body-article">
                            <!-- 아코디언 : accordion / 닫힘 : close -->
                            <section
                              :class="[
                                'article-section',
                                'section-010201',
                                'accordion',
                                { close: !isCaddieCareerVisible }
                              ]"
                            >
                              <div class="section-header">
                                <div class="header-left">
                                  <div
                                    class="header-title"
                                    @click="isCaddieCareerVisible = !isCaddieCareerVisible"
                                  >
                                    경력 정보
                                  </div>
                                </div>
                                <div class="header-right">
                                  <ul class="header-button">
                                    <li>
                                      <erp-button
                                          button-div="SAVE"
                                          :is-icon-custom="true"
                                          @click.native="caddieCareerGridAdd"
                                      >
                                        추가
                                      </erp-button>
                                    </li>
                                    <li>
                                      <erp-button
                                          button-div="DELETE"
                                          :is-icon-custom="true"
                                          @click.native="caddieCareerGridDelete"
                                      >
                                        삭제
                                      </erp-button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="section-body">
                                <div class="body-grid">
                                  <ejs-grid-wrapper
                                    ref="caddieCareerGrid"
                                    v-bind="caddieCareerOptions"
                                    :dataSource="caddieCareers"
                                    :validationRules="caddieCareerValidationRules"
                                    :validationModification="false"
                                    :aggregates="caddieCareerGridAggregates"
                                    @headerCellInfo="caddieCareerGridHeaderCellInfo"
                                  />
                                </div>
                              </div>
                            </section>
                            <!-- 아코디언 : accordion / 닫힘 : close -->
                            <section
                              :class="[
                                'article-section',
                                'section-010202',
                                'accordion',
                                { close: !isCaddieAcdmcrVisible }
                              ]"
                            >
                              <div class="section-header">
                                <div class="header-left">
                                  <div
                                    class="header-title"
                                    @click="isCaddieAcdmcrVisible = !isCaddieAcdmcrVisible"
                                  >
                                    학력 정보
                                  </div>
                                </div>
                                <div class="header-right">
                                  <ul class="header-button">
                                    <li>
                                      <erp-button
                                          button-div="SAVE"
                                          :is-icon-custom="true"
                                          @click.native="caddieAcdmcrGridAdd"
                                      >
                                        추가
                                      </erp-button>
                                    </li>
                                    <li>
                                      <erp-button
                                          button-div="DELETE"
                                          :is-icon-custom="true"
                                          @click.native="caddieAcdmcrGridDelete"
                                      >
                                        삭제
                                      </erp-button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="section-body">
                                <div class="body-grid">
                                  <ejs-grid-wrapper
                                    ref="caddieAcdmcrGrid"
                                    v-bind="caddieAcdmcrOptions"
                                    :dataSource="caddieAcdmcrs"
                                    :validationRules="caddieAcdmcrValidationRules"
                                    :validationModification="false"
                                    @headerCellInfo="caddieAcdmcrGridHeaderCellInfo"
                                  />
                                </div>
                              </div>
                            </section>
                          </article>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :ignore="isPopupOpened"
                  :is-shortcut-button="true"
                  :is-key-color="true"
                  @click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="caddieInfoDetailPopupClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <daum-postcode-popup
      v-if="isDaumPostcodePopupOpen"
      ref="daumPostcodePopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClose="daumPostcodePopupClosed"
      @popupConfirmed="daumPostcodePopupConfirmed"
    ></daum-postcode-popup>
    <cart-search-popup
      v-if="isCartSearchPopupOpen"
      ref="cartSearchPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      popupKey=""
      v-on:popupEvent="onCartSearchPopupEvent"
    ></cart-search-popup>
    <!-- <BasePopupWrapper ref="cartSearchPopupWrapper" :componentPath="`/game-progress/popup/CartSearchPopup.vue`" @basePopupEvent="onBasePopupEvent"/> -->
  </div>
</template>

<style scoped>
body .content-body .article-section.section-01 .section-body {overflow-y: scroll;}
body .content-body .article-section.section-0103 .section-body .body-grid{height:147px}
body .content-body .article-section.section-0104 .section-body .body-grid{height:147px}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  commonCodesGetCommonCode,
  commonCodeGetComCodeDefaultValue,
} from "@/utils/commonCodes";
import { getFormattedDate } from "@/utils/date";

import {
  getCaddieInfoDetail,
  getCaddieNameCheck,
  getCaddieHpNoCheck,
  getCaddieNoCheck,
  getMaxCaddieNo,
  saveCaddieInfoDetail,
  deleteCaddieInfoDetail,
} from "@/api/caddieInfoRegistration";
import { getCartInfoList } from "@/api/cartInfo";
import {
  formPreventEnterEvent,
  validateFormRefs,
  uploadImage,
} from "@/utils/formUtil";

import DaumPostcodePopup from "@/views/common/DaumPostcodePopup.vue";
import CartSearchPopup from "./CartSearchPopup";
import InputBirthday from "@/components/common/datetime/InputBirthday";
import InputDate from "@/components/common/datetime/InputDate";
import InputNumber from "@/components/common/InputNumber";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ComponentTelephone from "@/components/ComponentTelephone";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {Aggregate, Edit, ForeignKey, Resize} from "@syncfusion/ej2-vue-grids";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "caddieInfoDetailPopup",
  props: ["popupKey", "value", "position", "zIndex", "isModal", "isVisible"],
  components: {
    EjsGridWrapper,
    ComponentTelephone,
    InputTextarea,
    InputText,
    InputNumber,
    InputDate,
    DaumPostcodePopup,
    CartSearchPopup,
    InputBirthday,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data: function () {
    return {
      buttons: {
        browse: "등록",
      },
      isCaddieDefaultVisible: true,
      isGameProgressVisible: true,
      isCaddieCareerVisible: true,
      isCaddieAcdmcrVisible: true,
      bsnDate: null,
      isCaddieImageUploadedAndNotSaved: false,
      caddieFileUploaderExtensions: ".png, .jpg, .jpeg, .gif, .bmp",
      isDaumPostcodePopupOpen: false,
      isCartSearchPopupOpen: false,
      animationSettings: { effect: "None" },
      popupPosition: { X: this.$props.position.x, Y: this.$props.position.y },
      currentzIndex: this.$props.zIndex,
      caddieInfo: {
        caddieId: null,
        caddieName: null,
        birthDay: "1980-01-01",
        lunarCode: "SOLAR",
        sexCode: "F",
        entryDate: getFormattedDate(Date.now()),
        caddieEmployDiv: null,
        retireDate: null,
        employStatementFlag: false,
        lflyStatementFlag: false,
        marriFlag: false,
        hpNo: null,
        homeTelNo: null,
        vehicleKind: null,
        vehicleNo: null,
        homePostNo: null,
        homeAddr1: null,
        homeAddr2: null,
        caddieNo: null,
        argmtSno: null,
        caddieGroupDiv: null,
        caddieWorkDiv: null,
        caddieGrade: null,
        caddieFeeDiv: null,
        cartId: null,
        cartNo: null,
        remarks: null,
        orgCaddieEmployDiv: null,
        imageId: null,
        imagePath: null,
        brhsNo: null,
        familyRelation: null,
        reEntryFlag: false,
        iaEmployJoinDate : getFormattedDate(Date.now()),
        iaEmployCancelDate: null,
      },
      caddieInfoOption: {
        sexCodeCodesOptions: commonCodesGetCommonCode("SEX_CODE", true),
        caddieEmployDivCodesOptions: commonCodesGetCommonCode(
          "CADDIE_EMPLOY_DIV",
          true
        ),
        caddieGroupDivCodesOptions: commonCodesGetCommonCode(
          "CADDIE_GROUP_DIV",
          true
        ),
        caddieWorkDivCodesOptions: commonCodesGetCommonCode(
          "CADDIE_WORK_DIV",
          true
        ),
        caddieGradeCodesOptions: commonCodesGetCommonCode("CADDIE_GRADE", true),
        caddieFeeDivCodesOptions: commonCodesGetCommonCode(
          "CADDIE_FEE_DIV",
          true
        ),
      },
      validateRefList: {
        caddieName: {
          required: true,
        },
        birthDay: {
          required: true,
        },
        sexCode: {
          required: true,
        },
        entryDate: {
          required: true,
        },
        caddieEmployDiv: {
          required: true,
        },
        retireDate: {},
        caddieNo: {
          required: true,
        },
        argmtSno: {
          required: true,
        },
        caddieGroupDiv: {
          required: true,
        },
        caddieWorkDiv: {
          required: true,
        },
        caddieGrade: {
          required: true,
        },
        caddieFeeDiv: {
          required: true,
        },
      },
      caddieCareers: [],
      caddieAcdmcrs: [],
      caddieCareerValidationRules: {
        careerContents: {
          required: true
        }
      },
      caddieAcdmcrValidationRules: {
        acdmcrDiv: {
          required: true
        },
        schoolName: {
          required: true
        }
      },
      caddieCareerGridAggregates: [
        {
          columns: [
            {
              field: "careerTerm",
              aggregationType: "TotalCaption",
              customAggregate: "경력 년수",
              textAlign: "right"
            },
            {
              field: "careerYycnt",
              aggregationType: "TotalSum"
            }
          ]
        }
      ],
      caddieCareerOptions: {
        columns: [
          {
            field: "careerId",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "careerContents",
            headerText: "경력내용",
            minWidth: 16,
            type: "string",
            width: 180,
          },
          {
            field: "careerTerm",
            headerText: "기간",
            minWidth: 16,
            type: "string",
            width: 150,
          },
          {
            field: "careerYycnt",
            headerText: "경력년수",
            minWidth: 16,
            type: "number",
            width: 90,
            textAlign: "right",
            isNumericType: true,
            inputNumberProperty: {
              allowDot: true,
              allowMinus: true,
              maxLength: 3,
            },
          },
          {
            field: "remarks",
            headerText: "비고",
            minWidth: 16,
            type: "string",
            width: 350,
          }
        ],
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: 'Batch',
          showConfirmDialog: false,
          newRowPosition: 'Bottom',
        },
        provides: [Edit, Resize, ForeignKey, Aggregate],
        selectionSettings: {type: "Multiple", enableToggle: false}
      },
      caddieAcdmcrOptions: {
        columns: [
          {
            field: "acdmcrId",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "acdmcrDiv",
            headerText: "학력구분",
            minWidth: 16,
            type: "string",
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "ACDMCR_DIV",
            width: 90,
          },
          {
            field: "schoolName",
            headerText: "학교명",
            minWidth: 16,
            type: "string",
            width: 150,
          },
          {
            field: "acdmcrTerm",
            headerText: "기간",
            minWidth: 16,
            type: "string",
            width: 150,
          },
          {
            field: "majorName",
            headerText: "전공",
            minWidth: 16,
            type: "string",
            width: 120,
          },
          {
            field: "grdtnCode",
            headerText: "졸업구분",
            minWidth: 16,
            type: "string",
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "GRDTN_CODE",
            width: 90,
          },
          {
            field: "remarks",
            headerText: "비고",
            minWidth: 16,
            type: "string",
            width: 170,
          }
        ],
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: 'Batch',
          showConfirmDialog: false,
          newRowPosition: 'Bottom',
        },
        provides: [Edit, Resize, ForeignKey, Aggregate],
        selectionSettings: {type: "Single", enableToggle: false}
      }
    };
  },
  created: function () {
    // 캐디 ID 값 설정
    // this.caddieInfo.caddieId = this.$props.popupKey;

    this.bsnDate = getFormattedDate(new Date());
  },
  beforeDestroy() {
    if (this.isCaddieImageUploadedAndNotSaved) {
      this.deleteCaddieFile();
    }
  },
  mounted: function () {
    // this.loadData();
  },
  computed: {
    isPopupOpened() {
      return this.isDaumPostcodePopupOpen || this.isCartSearchPopupOpen;
    },
  },
  methods: {
    validateFormRefs,
    async fetchCaddieDetail(caddieId) {
      // 수정시에만 조회함
      const { value } = await getCaddieInfoDetail(caddieId);
      console.log(value);

      if (!value.caddieDetail.marriFlag) {
        value.caddieDetail = {
          marriFlag: null,
          ...value.caddieDetail,
        };
      }

      if (!value.caddieDetail.hpNo) {
        value.caddieDetail = {
          hpNo: null,
          ...value.caddieDetail,
        };
      }

      if (!value.caddieDetail.homeTelNo) {
        value.caddieDetail = {
          homeTelNo: null,
          ...value.caddieDetail,
        };
      }

      if (!value.caddieDetail.vehicleKind) {
        value.caddieDetail = {
          vehicleKind: null,
          ...value.caddieDetail,
        };
      }

      if (!value.caddieDetail.vehicleNo) {
        value.caddieDetail = {
          vehicleNo: null,
          ...value.caddieDetail,
        };
      }

      if (!value.caddieDetail.homePostNo) {
        value.caddieDetail = {
          homePostNo: null,
          ...value.caddieDetail,
        };
      }

      if (!value.caddieDetail.homeAddr1) {
        value.caddieDetail = {
          homeAddr1: null,
          ...value.caddieDetail,
        };
      }

      if (!value.caddieDetail.homeAddr2) {
        value.caddieDetail = {
          homeAddr2: null,
          ...value.caddieDetail,
        };
      }

      if (!value.caddieDetail.cartId) {
        value.caddieDetail = {
          cartId: null,
          ...value.caddieDetail,
        };
      }

      if (!value.caddieDetail.cartNo) {
        value.caddieDetail = {
          cartNo: null,
          ...value.caddieDetail,
        };
      }

      if (!value.caddieDetail.remarks) {
        value.caddieDetail = {
          remarks: null,
          ...value.caddieDetail,
        };
      }

      if (!value.caddieDetail.imageId) {
        value.caddieDetail = {
          imageId: null,
          ...value.caddieDetail,
        };
      }

      if (!value.caddieDetail.imagePath) {
        value.caddieDetail = {
          imagePath: null,
          ...value.caddieDetail,
        };
      }

      this.caddieInfo = value.caddieDetail;
      this.caddieCareers = value.caddieCareers;
      this.caddieAcdmcrs = value.caddieAcdmcrs;
    },
    loadData: async function () {
      if (this.caddieInfo.caddieId) {
        console.log("this.caddieInfo.caddieId.===>", this.caddieInfo.caddieId);
        await this.fetchCaddieDetail(this.caddieInfo.caddieId);
      } else {
        this.getMaxCaddieNo();

        this.caddieInfo.caddieEmployDiv = commonCodeGetComCodeDefaultValue(
          "CADDIE_EMPLOY_DIV"
        );
        this.caddieInfo.caddieGroupDiv = commonCodeGetComCodeDefaultValue(
          "CADDIE_GROUP_DIV"
        );
        this.caddieInfo.caddieWorkDiv = commonCodeGetComCodeDefaultValue(
          "CADDIE_WORK_DIV"
        );
        this.caddieInfo.caddieGrade = commonCodeGetComCodeDefaultValue(
          "CADDIE_GRADE"
        );
        this.caddieInfo.caddieFeeDiv = commonCodeGetComCodeDefaultValue(
          "CADDIE_FEE_DIV"
        );
      }
    },
    cartSearchPopupOpen() {
      // this.caddieInfo.caddieId = null
      this.isCartSearchPopupOpen = true; // 팝업을 띄울 때 생성되도록 하기 위함
      this.$nextTick(() => {
        this.$refs.cartSearchPopup.showPopup(this.caddieInfo.cartNo);
      });
    },
    onCartSearchPopupEvent(eventId, popupKey) {
      if (eventId === "popupClosed") {
        this.isCartSearchPopupOpen = false; // 추가 팝업 dom 제거
        this.caddieInfo.cartId = popupKey.cartId;
        this.caddieInfo.cartNo = popupKey.cartNo;
      }
    },
    onCartNoBlur: function (args) {
      if (args.value !== null) {
        this.caddieInfo.cartNo = this.caddieInfo.cartNo.trim();
        if (this.caddieInfo.cartNo === "") {
          this.caddieInfo.cartNo = null;
          this.caddieInfo.cartId = null;
        } else {
          this.getCartInfoList();
        }
      } else {
        this.caddieInfo.cartId = null;
      }
    },
    onCaddieEmployDivChange: function () {
      if (
        this.caddieInfo.caddieEmployDiv === "RETIRE" &&
        this.caddieInfo.retireDate === null
      ) {
        this.caddieInfo.retireDate = getFormattedDate(Date.now());
      } else if (this.caddieInfo.caddieEmployDiv === "EMPLOY") {
        this.caddieInfo.retireDate = null;
      }

      // Form Validation 설정
      if (this.caddieInfo.caddieEmployDiv === "EMPLOY") {
        this.validateRefList.retireDate = {}; // 퇴사일자 validator 제거
      } else {
        this.validateRefList.retireDate = { required: true }; // Validator 추가.
      }

      if (this.caddieInfo.caddieEmployDiv === "RETIRE") {
        this.caddieInfo.caddieNo = 0;
        this.caddieInfo.argmtSno = 0;
      }
    },
    onHpNoBlur: function () {
      const hpNo = this.caddieInfo.hpNo;

      if (hpNo !== null) {
        this.getCaddieHpNoCheck(hpNo, this.caddieInfo.caddieId);
      }
    },
    onCaddieNameBlur: function (args) {
      const caddieName = args.value;

      if (caddieName !== null) {
        this.getCaddieNameCheck(caddieName, this.caddieInfo.caddieId);
      }
    },
    onCaddieNoBlur: function (args) {
      const caddieNo = args.value;

      if (caddieNo !== null) {
        this.getCaddieNoCheck(caddieNo, this.caddieInfo.caddieId);
      }
    },
    async onFileSelect(args) {
      const file = args.event.target.files[0];

      try {
        const uploadResponse = await uploadImage(
          this.caddieInfo.imageId,
          "CADDIE",
          args.filesData,
          file
        );
        if (uploadResponse) {
          this.caddieInfo.imageId = uploadResponse.imageId;
          this.caddieInfo.imagePath = uploadResponse.imageURL;
          this.isCaddieImageUploadedAndNotSaved = true;
        }
      } catch (e) {
        alert(e.message);
      } finally {
        this.$refs.caddieFileUploader.clearAll(); // 다시 upload 하지 않도록 clear
      }
    },
    clearCaddieFiles() {
      this.$refs.caddieFileUploader.clearAll();

      if (this.caddieInfo.imageId && this.caddieInfo.imagePath) {
        this.deleteCaddieFile();
      }
    },
    async deleteCaddieFile() {
      try {
        await GolfErpAPI.deleteImage(this.caddieInfo.imageId);
        this.caddieInfo.imageId = null;
        this.caddieInfo.imagePath = null;
        this.isCaddieImageUploadedAndNotSaved = false;
      } catch (e) {
        console.error("imageDelete.err.===>", e);
      }
    },
    setZIndex: function (zIndex) {
      this.currentzIndex = zIndex;
    },
    caddieInfoInitButtonClick: function () {
      this.$emit("add");
    },
    onPostCodeClick: function () {
      this.onDaumPostcodePopupOpen();
    },
    onDaumPostcodePopupOpen: function () {
      this.isDaumPostcodePopupOpen = true;
      this.$nextTick(() => {
        this.$refs.daumPostcodePopup.showPopup();
      });
    },
    daumPostcodePopupClosed: function () {
      this.isDaumPostcodePopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기
    },
    daumPostcodePopupConfirmed: function (data) {
      this.caddieInfo.homePostNo = data.zonecode;
      this.caddieInfo.homeAddr1 = data.address;

      this.daumPostcodePopupClosed();
    },
    preventEnterEvent: formPreventEnterEvent,
    async showPopup(caddieId) {
      if (caddieId) {
        this.caddieInfo.caddieId = caddieId;
      } else {
        this.$refs.caddieName.focus();
      }

      try {
        await this.loadData();
      } catch (e) {
        this.$refs.caddieInfoDetailPopup.hide();
        throw e;
      }
    },
    dialogClose: function () {
      this.$emit(
        "popupEvent",
        "popupClosed",
        this.caddieInfo.caddieId,
        this.$props.value
      );
    },
    onDialogClicked: function () {
      this.$emit(
        "popupEvent",
        "popupClicked",
        this.$props.popupKey,
        this.$props.value
      );
    },
    onDeleteButtonClicked: async function () {
      if (this.caddieInfo.caddieId) {
        // 예/아니오 선택할 수 있는 팝업으로 변경해야함.
        if (
          !(await this.confirm(
            this.caddieInfo.caddieName + " 님의 캐디 정보를 삭제하시겠습니까?"
          ))
        ) {
          return;
        }
      } else {
        return this.errorToast("캐디 정보가 존재하지 않습니다");
      }

      this.deleteCaddieInfoDetail(this.caddieInfo.caddieId);
    },
    onSaveButtonClicked: async function () {
      if (!this.validate()) {
        return;
      }

      if (!this.$refs.caddieCareerGrid.validate()) {
        return;
      }

      if (!this.$refs.caddieAcdmcrGrid.validate()) {
        return;
      }

      if (
        this.caddieInfo.orgCaddieEmployDiv !== "RETIRE" &&
        this.caddieInfo.caddieEmployDiv === "RETIRE"
      ) {
        if (
          !(await this.confirm(this.caddieInfo.caddieName + " 님을 퇴사처리 하시겠습니까?"))
        ) {
          return;
        }
      }

      this.saveCaddieInfoDetail();
    },
    caddieInfoDetailPopupClose: function () {
      this.$refs.caddieInfoDetailPopup.hide();
    },
    onBasePopupEvent: function (eventId, popupKey, value) {
      // multi window test
      console.log("######## onBasePopupEvent ############");
      console.log(eventId);
      console.log(popupKey);
      console.log(value);
    },
    deleteCaddieInfoDetail: function (caddieId) {
      deleteCaddieInfoDetail(caddieId)
        .then((response) => {
          console.log("deleteCaddieInfoDetail.response.===>", response);

          this.infoToast(this.$t("main.popupMessage.deleted"));
          this.caddieInfoDetailPopupClose();
        })
        .catch((error) => {
          console.log("deleteCaddieInfoDetail.err.===>", error);
        });
    },
    saveCaddieInfoDetail: function () {
      const data = this.caddieInfo;

      const caddieCareesBatchChange = this.$refs.caddieCareerGrid.getBatchChanges();
      const caddieAcdmcrsBatchChange = this.$refs.caddieAcdmcrGrid.getBatchChanges();

      data.caddieCareers = {
        addedRecords: caddieCareesBatchChange.addedRecords,
        changedRecords: caddieCareesBatchChange.changedRecords,
        deletedRecords: caddieCareesBatchChange.deletedRecords
      };

      data.caddieAcdmcrs = {
        addedRecords: caddieAcdmcrsBatchChange.addedRecords,
        changedRecords: caddieAcdmcrsBatchChange.changedRecords,
        deletedRecords: caddieAcdmcrsBatchChange.deletedRecords
      };

      saveCaddieInfoDetail(data, this.bsnDate)
        .then((response) => {
          console.log("saveCaddieInfoDetail.response.===>", response);
          this.isCaddieImageUploadedAndNotSaved = false;

          if (response.value !== null && response.value !== undefined) {
            this.caddieInfo.caddieId = response.value;
          }

          this.infoToast(this.$t("main.popupMessage.saved"));

          this.fetchCaddieDetail(this.caddieInfo.caddieId);
        })
        .catch((error) => {
          console.log("saveCaddieInfoDetail.err.===>", error);
        });
    },
    getMaxCaddieNo: function () {
      getMaxCaddieNo()
        .then((response) => {
          console.log("getMaxCaddieNo.===>", response);
          if (response.value !== null && response.value !== undefined) {
            this.caddieInfo.caddieNo = response.value.maxCaddieNo;
            if (!this.caddieInfo.argmtSno) {
              this.caddieInfo.argmtSno = response.value.maxArgmtSno;
            }
          }
        })
        .catch((error) => {
          console.log("getMaxCaddieNo.err.===>", error);
        });
    },
    getCaddieHpNoCheck: function (hpNo, caddieId) {
      getCaddieHpNoCheck(hpNo, caddieId, true)
        .then((response) => {
          if (response.value !== null && response.value !== undefined) {
            this.errorToast(response.value);
          }
        })
        .catch((error) => {
          console.log("getCaddieHpNoCheck.err.===>", error);
        });
    },
    getCaddieNameCheck: function (caddieName, caddieId) {
      getCaddieNameCheck(caddieName, caddieId, true)
        .then((response) => {
          if (response.value !== null && response.value !== undefined) {
            this.errorToast(response.value);
          }
        })
        .catch((error) => {
          console.log("getCaddieNameCheck.err.===>", error);
        });
    },
    getCaddieNoCheck: function (caddieNo, caddieId) {
      getCaddieNoCheck(caddieNo, caddieId)
        .then((response) => {
          console.log("getCaddieNoCheck.===>", response);
        })
        .catch((error) => {
          console.log("getCaddieNoCheck.err.===>", error);
          this.caddieInfo.caddieNo = null;
        });
    },
    getCartInfoList: function () {
      const cartKind = null;
      const cartNo = this.caddieInfo.cartNo;
      const dsuseFlag = false;

      getCartInfoList(cartKind, cartNo, dsuseFlag)
        .then((response) => {
          console.log("getCartInfoList.===>", response);
          if (response.value.cartInfoList.length === 0) {
            this.caddieInfo.cartId = null;
            this.caddieInfo.cartNo = null;
            const returnMessage = '"' + cartNo + '" 카트는 미등록 카트입니다.';
            this.errorToast(returnMessage);
          } else {
            this.caddieInfo.cartId = response.value.cartInfoList[0].cartId;
          }
        })
        .catch((error) => {
          console.log("getCartInfoList.err.===>", error);
        });
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
    caddieCareerGridAdd() {
      this.$refs.caddieCareerGrid.addRecord({
        careerId: null,
        careerContents: null,
        careerTerm: null,
        careerYycnt: 0,
        remarks: null
      });
    },
    async caddieCareerGridDelete() {
      if (!(await this.confirm("선택한 경력 정보를 삭제하시겠습니까?"))) {
        return;
      }
      this.$refs.caddieCareerGrid.deleteRecord();
    },
    caddieAcdmcrGridAdd() {
      this.$refs.caddieAcdmcrGrid.addRecord({
        acdmcrId: null,
        acdmcrDiv: null,
        schoolName: null,
        acdmcrTerm: null,
        majorName: null,
        grdtnCode: null,
        remarks: null
      });
    },
    async caddieAcdmcrGridDelete() {
      if (!(await this.confirm("선택한 학력 정보를 삭제하시겠습니까?"))) {
        return;
      }
      this.$refs.caddieAcdmcrGrid.deleteRecord();
    },
    caddieCareerGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field
          }
        }
      } = args;

      if (field === "careerContents") {
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    caddieAcdmcrGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field
          }
        }
      } = args;

      if (
        field === "acdmcrDiv" ||
        field === "schoolName"
      ) {
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    }
  },
};
</script>
